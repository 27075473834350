@import '/src/styles/utilities.scss';

.dialogWrapper__trigger {
  @include undo-default-button-styles();

  display: flex;
  align-items: center;

  &:focus-visible {
    outline: 1px solid var(--color-blue-300);
  }
}

.dialogWrapper__content {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  background-color: var(--color-white);
}

.dialogWrapper__close {
  @include undo-default-button-styles();

  display: flex;
  justify-content: center;
  padding: $space-16;

  &:focus-visible {
    outline: 1px solid var(--color-gocomics-blue);
  }
}
