@import '/src/styles/utilities.scss';

// TODO: update these class names
.mobileNavigation__list {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: var(--color-white);
}

.mobileNavigation__subListBackButton {
  display: flex;
  gap: $space-20;
  align-items: center;
  padding: $space-20 $space-16 $space-20 $space-12;
  color: var(--color-gray-800);

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-300);
  }
}

.mobileNavigation__subListItem {
  display: flex;
  gap: $space-16;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-300);

  &:hover {
    background-color: var(--color-gray-200);
  }
}

.mobileNavigation__link {
  display: flex;
  gap: $space-12;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: $space-24 $space-16 $space-24 $space-76;
  color: var(--color-gray-700);

  &:hover {
    color: var(--color-gray-700);
  }
}

.mobileNavigation__subMenuTrigger {
  @include undo-default-button-styles();

  display: flex;
  gap: $space-12;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: $space-24 $space-16 $space-24 $space-68;
  color: var(--color-gray-700);

  &:focus {
    outline: 2px solid var(--color-gocomics-blue);
    outline-offset: $space-4;
  }
}

.mobileNavigation__close {
  @include undo-default-button-styles();

  display: flex;
  justify-content: center;
  padding: $space-16;
}

.mobileNavigation__headerText {
  margin-bottom: 0;
}

.mobileNavigation__submenuImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}

.mobileNavigation__footerImage {
  position: absolute;
  bottom: -50px;
  max-width: 75%;
  height: auto;
}

.mobileNavigation__submenu {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  background-color: var(--color-white);
}
