@import '/src/styles/utilities.scss';

.authButtons__signIn {
  @include undo-default-button-styles();
  @include apply-display-styles('featureNav');

  height: 100%;
  color: var(--color-white);
  white-space: nowrap;

  &:focus {
    outline: 1px solid var(--color-blue-300);
  }
}

.authButtons__subscribe {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}
