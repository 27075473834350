@import '/src/styles/utilities.scss';

.mobileNavigation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.mobileNavigation__trigger {
  @include undo-default-button-styles();

  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);

  &:focus {
    outline: 1px solid var(--color-blue-300);
  }
}

.mobileNavigation__menu {
  z-index: $zindex-popover;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
}

.mobileNavigation__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mobileNavigation__closeButton {
  align-self: flex-end;
}

.mobileNavigation__list {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: var(--color-white);
}

.mobileNavigation__listItem {
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-300);
  }

  &:hover {
    background-color: var(--color-gray-200);
  }

  div {
    width: 100%;
  }
}

.mobileNavigation__link {
  display: flex;
  gap: $space-12;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: $space-24 $space-16 $space-24 $space-68;
  color: var(--color-gray-700);

  &:hover {
    color: var(--color-gray-700);
  }
}

.mobileNavigation__authButtons {
  display: flex;
  flex-direction: column;
  gap: $space-20;
  width: 100%;
  max-width: 34rem;
  padding-bottom: $space-16;
  margin: 0 auto;
}

.mobileNavigation__signin {
  @include undo-default-button-styles();

  text-align: center;

  &:focus {
    outline: 2px solid var(--color-gocomics-blue);
    outline-offset: $space-4;
  }
}

.mobileNavigation__close {
  @include undo-default-button-styles();

  display: flex;
  justify-content: center;
  padding: $space-16;
}
