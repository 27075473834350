@import '/src/styles/utilities.scss';

.navigationWrapper__trigger {
  @include undo-default-button-styles();

  position: relative;
  white-space: nowrap;

  &:focus-visible {
    outline: 1px solid var(--color-blue-300);
  }

  // Little circle
  &[data-state='open'] {
    &:after {
      position: absolute;
      bottom: -1.75rem;
      left: 50%;
      z-index: $zindex-active;
      width: $space-16;
      height: $space-16;
      overflow: hidden;
      text-align: center;
      content: '';
      background-color: var(--color-white);
      border-radius: 50%;
      transform: translateX(-50%);

      @include media-breakpoint-up(lg) {
        bottom: -2.75rem;
      }
    }
  }
}

.navigationWrapper__content {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: $zindex-popover;
  width: 334px;
  overflow: hidden;
  background-color: var(--color-white);
  border-radius: 0 0 $border-radius-15 $border-radius-15;
  box-shadow: $elevation-4;
  transform: translateY(100%);
}
