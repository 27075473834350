@import '/src/styles/utilities.scss';

$trigger-size: 32px;

.triggerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-light);

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    outline: 1px solid var(--color-blue-300);
  }

  &_desktop {
    gap: $space-12;
    border: none;
    border-radius: $border-radius-50 0 0 $border-radius-50;
  }

  &_mobile {
    width: $trigger-size;
    height: $trigger-size;
  }
}
